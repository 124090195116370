<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    :multiple="multiple"
    hide-no-data
    hide-selected
    chips
    deletable-chips
    small-chips
    :readonly="readonly"
    :label="label"
    placeholder="Start typing to search"
    :error-messages="errorMessages || []"
  ></v-autocomplete>
</template>
<script>
import http from '@/http'

export default {
  props: {
    value: {},
    apiPath: {},
    filters: {},
    label: {},
    textField: {},
    errorMessages: {},
    multiple: {},
    readonly: {type: Boolean, default: false},
  },
  data () {
    return {
      model: (this.value && this.value.id) ? this.value.id : this.value,
      items: [],
      search: null,
      isLoading: false,
    }
  },
  watch: {
    model (v) {
      this.$emit('input', v)
    },
    search: {
      async handler (val) {
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        let response = await http.get(this.apiPath, {params: {...this.filters, q: val}})
        this.items = response.data.results.map(i => {
          return {
            text: i[this.textField],
            value: i.id,
          }
        })
        this.isLoading = false
      },
      immediate: true
    }
  }
}
</script>