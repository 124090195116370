<template>
  <v-container>
    <license-table v-bind="props" :update-route="true"></license-table>
  </v-container>
</template>
<script>

import LicenseTable from '@/components/LicenseTable'
import {licenseTable} from '@/tables'

export default {
  components: {
    LicenseTable
  },
  data () {
    return {
      props: licenseTable
    }
  }
}
</script>